"use strict";

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
  var name = cookieName + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(';');
  for (var i = 0; i < cookieArray.length; i++) {
      var cookieItem = cookieArray[i];
      while (cookieItem.charAt(0) === ' ') {
          cookieItem = cookieItem.substring(1);
      }
      if (cookieItem.indexOf(name) === 0) {
          return cookieItem.substring(name.length, cookieItem.length);
      }
  }
  return '';
}
/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
  homeshow: function () {
    $(function () {
      var deviceType = "Desktop";
      var ua = navigator.userAgent;

      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        deviceType = "Tablet";
      }

      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        deviceType = "Mobile";
      }

      var groupName = getCookie('groupTypeInfo');
    // Triggers when DOWNLOAD RESOURCE button in Home page is clicked
    $("body").on("click", ".button-component", function (e) {
    var text = $(this).find("a").eq(0).text();
      var correctText = text.replace(/|n/g, "").trim();
      if (correctText == "DOWNLOAD RESOURCE") {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var link_text = correctText;
        var link_url = $(this).find("a").attr("href");
        var segment_type = groupName;
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var url = String(link_url);
        var file_name = url.substring(
          url.lastIndexOf("/") + 1,
          url.lastIndexOf("?")
        );
        var file_extension = file_name.split(".").pop();
        if (window.gtmGA4Enabled) {
        dataLayer.push({
          event: "file_download_resource",
          file_name: file_name,
          page_name: page_name,
          page_location: page_location,
          file_extension: file_extension,
          link_text: link_text,
          hit_timestamp: hit_timestamp,
          segment_type: segment_type,
          link_url: link_url
        });
      }
    }
  })
  // Triggers when add to cart button in Home page is clicked
    $("body").on("click", ".add-to-cart", function () {
      var page_name = $("head title").text();
      var page_location = window.location.href;
      var segment_type = groupName;
      var today = new Date();
      var hit_timestamp =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var item_name = $(this).attr("data-pname");
      if(window.gtmGA4Enabled) {
      dataLayer.push({
        event: "add_sample_to_cart",
        page_name: page_name,
        page_location: page_location,
        hit_timestamp: hit_timestamp,
        item_name: item_name,
        segment_type: segment_type,
        page_type: "Home_page"
      });
    }
    });
    });
  },
  productshow: function () {
    $(function () {
      var groupName = getCookie('groupTypeInfo');
      var deviceType = "Desktop";
      var ua = navigator.userAgent;

      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        deviceType = "Tablet";
      }

      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        deviceType = "Mobile";
      }
      // Triggers when add to cart button in PDP page is clicked
    

        
      $("body").on("click", ".add-to-cart, .add-to-cart-global", function () {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var today = new Date();
        var segment_type = groupName;
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: "add_sample_to_cart",
          page_name: page_name,
          page_location: page_location,
          hit_timestamp: hit_timestamp,
          item_name: document.getElementsByClassName("product-name")[0].innerText,
          segment_type: segment_type,
          page_type: "PDP"
        });
      }
      });
    });
  },
  productshowincategory: function () {
    var groupName = getCookie('groupTypeInfo');
    // Triggers when add to cart button in PLP page is clicked
    $("body").on("click", ".add-to-cart", function () {
      var page_name = $("head title").text();
      var page_location = window.location.href;
      var segment_type = groupName;
      var today = new Date();
      var hit_timestamp =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var item_name = $(this).attr("data-pname");
      if(window.gtmGA4Enabled) {
      dataLayer.push({
        event: "add_sample_to_cart",
        page_name: page_name,
        page_location: page_location,
        hit_timestamp: hit_timestamp,
        item_name: item_name,
        segment_type: segment_type,
        page_type: "PLP"
      });
    }
    });
  },
  searchshow: function () {
    var groupName = getCookie('groupTypeInfo');
    $("body").on(
      "click",
      ".product .image-container a:not(.quickview), .product .pdp-link a",
      function () {
        var $ele = $(this).closest(".product");
        var gtmdata =
          $ele.data("gtmdata") || $.parseJSON($ele.attr("data-gtmdata"));
      }
    );
  // Triggers when add to cart button in PLP page is clicked
    $("body").on("click", ".add-to-cart", function () {
      var page_name = $("head title").text();
      var page_location = window.location.href;
      var today = new Date();
      var segment_type = groupName;
      var hit_timestamp =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var item_name = $(this).attr("data-pname");
      if(window.gtmGA4Enabled) {
      dataLayer.push({
        event: "add_sample_to_cart",
        page_name: page_name,
        page_location: page_location,
        hit_timestamp: hit_timestamp,
        item_name: item_name,
        segment_type: segment_type,
        page_type: 'PLP'
      });
    }
    });

    $(function () {
      var deviceType = "Desktop";
      var ua = navigator.userAgent;

      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        deviceType = "Tablet";
      }

      if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        deviceType = "Mobile";
      }
      var dataSearchKeywords = $(".container.search-results").attr(
        "data-searchkeywords"
      );

      var pidsProductsName = [];
      var pidsProductsId = [];
      var products = $(".product");
    });
  },
  cartshow: function () {},
  checkoutbegin: function () {
  },
  orderconfirm: function () {
    var groupName = getCookie('groupTypeInfo');
    //Triggers order is confirmed/Thankyou page
    var page_name = $("head title").text();
    var page_location = window.location.href;
    var today = new Date();
    var segment_type = groupName;
    var hit_timestamp =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      if(window.gtmGA4Enabled) {
    dataLayer.push({
      event: "order_confirmed",
      page_name: page_name,
      page_location: page_location,
      segment_type: segment_type,
      hit_timestamp: hit_timestamp
    });
  }
  },
  // events that should happen on every page
  all: function () {

    var groupName = getCookie('groupTypeInfo');

    //Triggers when sign up/registration form is submitted successfully
    $("body").on("submit", "form.registration-sign-up", function (e) {
      var page_name = $("head title").text();
      var page_location = window.location.href;
      var form_name = "Sign Up";
      var form_type = "Registration";
      var today = new Date();
      var hit_timestamp =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if(window.gtmGA4Enabled) {
      dataLayer.push({
        event: "signup_complete",
        page_name: page_name,
        page_location: page_location,
        hit_timestamp: hit_timestamp,
        form_name: form_name,
        form_type: form_type
      });
    }
    });

    //Triggers only when proceed to checkout button in mincart and cart page is clicked
    $('body').on('click', '.checkout-btn', function (e) {
        var page_name = $('head title').text();
        var page_location = window.location.href;
        var today = new Date();
        var segment_type = groupName;
        var hit_timestamp = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
        if(window.gtmGA4Enabled) {
        dataLayer.push({
            event:'proceed_to_checkout',
            page_name: page_name,
            page_location: page_location,
            segment_type: segment_type,
            hit_timestamp: hit_timestamp
        });
      }
    });

    //Triggers only when find in store button in pdp page is clicked
    $("body").on("click", ".store-locate", function (e) {
      var page_name = $("head title").text();
      var page_location = window.location.href;
      var item_name = $(".product-name:first").text();
      var today = new Date();
      var segment_type = groupName;
      var hit_timestamp =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if(window.gtmGA4Enabled) {
      dataLayer.push({
        event: "find_in_store_click",
        item_name: item_name,
        page_name: page_name,
        page_location: page_location,
        segment_type: segment_type,
        hit_timestamp: hit_timestamp
      });
    }
    });

    //Triggers only when a plp tile is clicked in plp search page loads
    if ($(".search-keywords").text() != "") {
      $(".grid-tile-wrapper").on("click", function () {
        var search_result_position = $(this).index() + 1;
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var link_url = $(this).find("a").attr("href");
        var search_term = $(".search-keywords").text().split("\n")[1].trim();
        var segment_type = groupName;
        var total_products = $(".product-search")
          .text()
          .split("\n")[1]
          .split(" ")
          .pop()
          .split("(")
          .pop()
          .split(")")[0];
        var num_search_results = parseInt(total_products, 10);
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: "search_select_result",
          page_location: page_location,
          search_term: search_term,
          num_search_results: num_search_results,
          hit_timestamp: hit_timestamp,
          page_name: page_name,
          search_result_url: link_url,
          segment_type: segment_type,
          search_result_position: search_result_position
        });
      }
      });
    }

    //Triggers when search keyword is entered and plp search page loads
    if ($(".search-keywords").text() != "") {
      $(function () {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var search_term = $(".search-keywords").text().split("\n")[1].trim();
        var segment_type = groupName;
        var total_products = $(".product-search")
          .text()
          .split("\n")[1]
          .split(" ")
          .pop()
          .split("(")
          .pop()
          .split(")")[0];
        var num_search_results = parseInt(total_products, 10);
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: 'search',
          page_location: page_location,
          search_term: search_term,
          num_search_results: num_search_results,
          hit_timestamp: hit_timestamp,
          segment_type: segment_type,
          page_name: page_name
        });
      }
      });
    }
    
    $("body .next-step-button").on(
      "click",
      ":button.submit-shipping",
      function (e) {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var segment_type = groupName;
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: "add_shipping_info",
          page_name: page_name,
          page_location: page_location,
          segment_type: segment_type,
          hit_timestamp: hit_timestamp
        });
      }
      }
    );

   // Triggers the events when submit placeorder button is clicked
    $("body .next-step-button").on(
      "click",
      ":button.place-order",
      function (e) {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var segment_type = groupName;
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: "place_order_submit",
          page_name: page_name,
          page_location: page_location,
          segment_type: segment_type,
          hit_timestamp: hit_timestamp
        });
      }
      }
    );

    // Triggers the events when nav_footer links are clicked
    $("body").on("click", "ul.menu-footer li", function () {
      var page_name = $("head title").text();
      var page_location = window.location.href;
      var footer_nav_text = $(this).find("a").text();
      var link_url = $(this).find("a").attr("href");
      var segment_type = groupName;
      var today = new Date();
      var hit_timestamp =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if(window.gtmGA4Enabled) {
      dataLayer.push({
        event: "nav_footer",
        page_name: page_name,
        page_location: page_location,
        hit_timestamp: hit_timestamp,
        link_url: link_url,
        segment_type: segment_type,
        footer_nav_text: footer_nav_text
      });
    }
    });

// Triggers the events when top_nav_header account button is clicked
    $("body").on("click", ".popover ul.nav li.nav-item", function () {
      if ($(this).find("a").eq(0).text() == "My Account") {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var top_nav_text = $(this).find("a").eq(0).text();
        var link_url = $(this).find("a").attr("href");
        var segment_type = groupName;
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: "top_nav_header",
          page_name: page_name,
          page_location: page_location,
          hit_timestamp: hit_timestamp,
          link_url: link_url,
          segment_type: segment_type,
          top_nav_text: top_nav_text
        });
      }
      }
    });

  // Triggers the events when top_nav_header menu are clicked
    $("body").on("click", "li.nav-item a.nav-link", function () {
      var page_name = $("head title").text();
      var page_location = window.location.href;
      var top_nav_text = $(this).eq(0).text();
      var link_url = $(this).attr("href");
      var segment_type = groupName;
      var today = new Date();
      var hit_timestamp =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if(window.gtmGA4Enabled) {
      dataLayer.push({
        event: "top_nav_header",
        page_name: page_name,
        page_location: page_location,
        hit_timestamp: hit_timestamp,
        link_url: link_url,
        segment_type: segment_type,
        top_nav_text: top_nav_text
      });
    }
    });

    // Triggers the events when sub_nav_headers menu are clicked
    $("body").on("click", "li.dropdown-item a.dropdown-link", function () {
      var page_name = $("head title").text();
      var page_location = window.location.href;
      var sub_nav_text = $(this).eq(0).text();
      var link_url = $(this).attr("href");
      var segment_type = groupName;
      var today = new Date();
      var hit_timestamp =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if(window.gtmGA4Enabled) {
      dataLayer.push({
        event: "sub_nav_header",
        page_name: page_name,
        page_location: page_location,
        hit_timestamp: hit_timestamp,
        link_url: link_url,
        segment_type: segment_type,
        sub_nav_text: sub_nav_text
      });
    }
    });

  // Triggers the events when "ORDER SAMPLES" button is clicked
    $("body").on("click", ".button-component", function (e) {
      if ($(this).find("a").eq(0).text() == "LEARN MORE") {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var link_text = $(this).find("a").eq(0).text();
        var link_url = $(this).find("a").attr("href");
        var segment_type = groupName;
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: "homepage_learn_more_click",
          link_text: link_text,
          link_url: link_url,
          page_name: page_name,
          page_location: page_location,
          segment_type: segment_type,
          hit_timestamp: hit_timestamp
        });
      }
      }

    // Triggers the events when "ORDER SAMPLES" button is clicked
      if ($(this).find("a").eq(0).text() == "ORDER SAMPLES") {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var link_text = $(this).find("a").eq(0).text();
        var link_url = $(this).find("a").attr("href");
        var segment_type = groupName;
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: "order_sample_click",
          link_text: link_text,
          link_url: link_url,
          page_name: page_name,
          page_location: page_location,
          segment_type: segment_type,
          hit_timestamp: hit_timestamp
        });
      }
      }

    // Triggers the events when "GET COUPONS" button is clicked
      if ($(this).find("a").eq(0).text() == "GET COUPONS") {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var link_url = $(this).find("a").attr("href");
        var coupon_name = "CETAPHIL PATIENT COUPON";
        var segment_type = groupName;
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
          if(window.gtmGA4Enabled) {
        dataLayer.push({
          event: "get_coupon_click",
          page_name: page_name,
          page_location: page_location,
          hit_timestamp: hit_timestamp,
          segment_type: segment_type,
          coupon_name: coupon_name
        });
      }
      }

    // Triggers the events when "DOWNLOAD BROCHURE" button is clicked
      var text = $(this).find("a").eq(0).text();
      var correctText = text.replace(/|n/g, "").trim();
      if (correctText == "DOWNLOAD BROCHURE") {
        var page_name = $("head title").text();
        var page_location = window.location.href;
        var link_text = correctText;
        var link_url = $(this).find("a").attr("href");
        var segment_type = groupName;
        var today = new Date();
        var hit_timestamp =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var url = String(link_url);
        var file_name = url.substring(
          url.lastIndexOf("/") + 1,
          url.lastIndexOf("?")
        );
        var file_extension = file_name.split(".").pop();
        if (window.gtmGA4Enabled) {
        dataLayer.push({
          event: "file_download",
          file_name: file_name,
          page_name: page_name,
          page_location: page_location,
          file_extension: file_extension,
          link_text: link_text,
          hit_timestamp: hit_timestamp,
          segment_type: segment_type,
          link_url: link_url
        });
      }
    }
    });
  },
};



/**
 * @param {String} categoryObject The category ID
 * @description gets the data for a product click
 */
function addCategory(categoryObject) {
  var obj = {
    event: "addCategory",
    ecommerce: {
      load: {
        actionField: {list: "Search Results"},
      },
    },
  };
  obj.ecommerce.load.category = categoryObject;
  dataLayer.push(obj);
}

/**
 * @param {String} productId The product ID
 * @description gets the data for a product click
 */
function productClick(productObject) {
  var obj = {
    event: "productClick",
    ecommerce: {
      click: {
        actionField: {list: "Search Results"},
        products: [],
      },
    },
  };
  obj.ecommerce.click.products.push(productObject);
  dataLayer.push(obj);
}

/**
 * @param productId
 * @description Click event for add product to cart
 */
function addToCart(productObject, quantity) {
  var quantObj = {quantity: quantity},
    obj = {
      event: "addToCart",
      ecommerce: {
        add: {
          products: [],
        },
      },
    };
  obj.ecommerce.add.products.push($.extend(productObject, quantObj));

  dataLayer.push(obj);
}

/**
 * @param productId
 * @description Click event for add product to cart
 */
function addToCartGA4(productObject, quantity) {
  var quantObj = {quantity: quantity};
  var obj = {
    event: "add_to_cart",
    ecommerce: {
      currency: productObject.currency,
      items: [$.extend(productObject, quantObj)],
      value: (Number(productObject.price) * Number(quantity)).toFixed(2),
    },
  };

  dataLayer.push(obj);
}

/**
 * @function removeFromCart
 * @description Click event for remove product from cart
 */
function removeFromCart(productObject, quantity) {
  var quantObj = {quantity: quantity};
  var obj = {
    event: "removeFromCart",
    ecommerce: {
      remove: {
        products: [],
      },
    },
  };
  obj.ecommerce.remove.products.push($.extend(productObject, quantObj));

  dataLayer.push(obj);
}

/**
 * @function removeFromCartGA4
 * @description Click event for remove product from cart
 */
function removeFromCartGA4(productObject, quantity) {
  var quantObj = {quantity: quantity};
  var obj = {
    event: "remove_from_cart",
    ecommerce: {
      currency: productObject.currency,
      items: [$.extend(productObject, quantObj)],
      value: (Number(productObject.price) * Number(quantity)).toFixed(2),
    },
  };

  dataLayer.push(obj);
}

/**
 * @function pushEvent
 * @description Convenience method for creating a click event.
 * @param {String} event
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventlabel
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
  dataLayer.push({
    event: event,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
}

/**
 * @function init
 * @description Initialize the tag manager functionality
 * @param {String} nameSpace The current name space
 */
$(document).ready(function () {
  if (pageAction && events[pageAction]) {
    events[pageAction]();
  }
  events.all();

  var queryString = window.location.search;
  var parameters = new URLSearchParams(queryString);
  if (parameters) {
    var crmId = parameters.get("crmid");
  }

  var cookie = document.cookie.split(";").find((cookiename) => {
    var cookieId = cookiename.replace(/ /g, "");
    if (cookieId.split("=")[0] === "_ga") {
      return cookieId;
    }
  });

  var cookieValue = cookie
    ? cookie.split("=")[1].split(".").slice(2).join(".")
    : null;

  var eventDatalayer = {
    event: "traking_user",
    cookieId: cookieValue,
  };

  if (crmId) {
    eventDatalayer.crmId = crmId;
  }
});

/**
 * listener for ajax events
 */
function gtmEventLoader() {
  try {
    $(document).ajaxSuccess(function (event, request, settings, data) {
      if (settings.dataTypes.indexOf("json") > -1) {
        if (data && "__gtmEvents" in data && Array.isArray(data.__gtmEvents)) {
          data.__gtmEvents.forEach(function gtmEvent(gtmEvent) {
            if (gtmEvent) {
              dataLayer.push(gtmEvent);
            }
          });
        }
      }
    });
    document.removeEventListener("DOMContentLoaded", gtmEventLoader);
  } catch (e) {
    console.error(e);
  }
}

/**
 * setup ajax event listener
 */
if (document.readyState === "complete") {
  gtmEventLoader();
} else {
  document.addEventListener("DOMContentLoaded", gtmEventLoader);
}
